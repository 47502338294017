/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Flex,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Spacer,
	Text,
	SkeletonText,
	useToast,
	useDisclosure,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Tag,
	Portal,
	Button,
	Switch,
	Tooltip,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import Scrollbar from 'react-custom-scrollbars-2'
import ReactExport from 'react-data-export'
import { AiFillDatabase } from 'react-icons/ai'
import { BiArrowBack, BiDownload } from 'react-icons/bi'
import { MdRefresh, MdSearch, MdPrint } from 'react-icons/md'
import { BeatLoader, PropagateLoader } from 'react-spinners'
import { useDashboardContext } from '../../../../context/dashboard.context'
import { useSharedContext } from '../../../../context/shared.context'
import { useAuthContext } from '../../../../context/auth.context'
import { useLanguageContext } from '../../../../context/locale.context'
import {
	useLoadWidgetDataHeaders,
	useLoadWidgetDrillDownData,
	useLoadWidgetExportData,
	useCheckDottedLineManagerAccess,
	useLoadAssetReportees,
	useSMEDrillDownData,
	useAdditionalFieldUpdate,
} from '../../../../hooks/dashboard.hooks'
import { sharedConst } from '../../../../utils/action.constant'
import {
	getInlineFields,
	getValueInMillions,
	parseDataForExcelExport,
	getInlineLeaveFields,
	getInlineReferenceFields,
} from '../../../../utils/dashboard.utils'
import {
	useLoadActivityListV1,
	useSubmitForm,
	useLoadActivityTypeList,
	useLoadActivityStatusList,
	useLoadActivityDetails,
} from '../../../../hooks/activity.hooks'
import { useLoadFormDynamicFieldList } from '../../../../hooks/form.hooks'
import { getCustomFormatttedDate } from '../../../../utils/date.utils'
import { WIDGET_WORKSPACE_MODE } from '../../../../utils/widget-constants'
import { useDrilldownContext } from './../../../../context/drilldown.context'
import {
	drilldownConst,
	dashboardConst,
} from './../../../../utils/action.constant'
import DrillDownTable, {
	TH,
	TD,
	TR,
} from '../../../../components/dashboard-table'
import DrillDownCard from '../../../../components/dashboard-card'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../../repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../../../repository/repo.utils'
import Select from '../../../../components/chakra-react-select'
import { getMessageUniqueId } from '../../../../utils/common.util'
import {
	formatDDMMYYYYHHMM,
	getCurrentDateUTC,
	getLeaveTimeDate,
} from '../../../../utils/date.utils'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import { useHistory } from 'react-router-dom'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

var objectHash = require('object-hash')

function WidgetCardData({ onWidgetViewChange, clearWidgetData, onToggle }) {
	const toast = useToast()
	const {
		state: { widgetData, queryFilters },
		dispatch: dashboardDispatch,
	} = useDashboardContext()

	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()

	const { mutate: mutateLoadReportees } = useLoadAssetReportees()

	const userData = authData
	const history = useHistory()

	const {
		dispatch,
		// state: { isShowLoader },
	} = useSharedContext()

	const {
		dispatch: dispatchDrilldown,
		state: { drilldownFilterStatus },
	} = useDrilldownContext()

	const {
		mutate: loadWidgetHeaders,
		data: widgetHeaderList,
		isLoading: isWidgetHeaderLoading,
	} = useLoadWidgetDataHeaders()

	const { mutate: mutateCheckDottedLineManagerAccess } =
		useCheckDottedLineManagerAccess()

	const {
		mutate: loadWidgetDrillDownData,
		isLoading: isWidgetDataLoading,
		data: widgetDrilldownData,
	} = useLoadWidgetDrillDownData()

	const { mutate: loadSMEDrillDownData } = useSMEDrillDownData()

	const {
		mutate: loadWidgetExportData,
		data: widgetExportList,
		isLoading: isExportDataLoading,
	} = useLoadWidgetExportData()

	const { mutate: loadActivity } = useLoadActivityListV1()

	const { mutate: dynamicFields, data: dynamicFieldsList } =
		useLoadFormDynamicFieldList()
	const { mutate: mutateSubmitForm } = useSubmitForm()
	const { mutate: mutateAdditionalFieldUpdate } = useAdditionalFieldUpdate()
	const { mutate: mutateActivityTypes } = useLoadActivityTypeList()
	const { mutate: mutateActivityStatus } = useLoadActivityStatusList()
	const { mutate: mutateActivity } = useLoadActivityDetails()

	const [search, setSearch] = useState('')
	const [isExceedOpen, setIsExceedOpen] = useState(false)
	const [widgetRecords, setWidgetRecords] = useState([])
	const [sortType, setSortType] = useState(null)
	const [sortField, setSortField] = useState(null)
	const [selectedCheckbox, setSelectedCheckbox] = useState({})
	const [hasMoreData, setHasMoreData] = useState(true)
	const [widgetDataHash, setWidgetDataHash] = useState({})
	const [selectedId, setSelectedId] = useState(0)
	const [scrollAtBottom, setScrollAtBottom] = useState(false)
	const [activityType, setActivityType] = useState(null)
	const [activityStatus, setActivityStatus] = useState(null)
	const [leaveData, setLeaveData] = useState([])
	const [freezeAct, setFreezeAct] = useState([])

	const { isOpen, onClose, onOpen } = useDisclosure()
	const isMobileView = localStorage.getItem('IS_MOBILE_VIEW')

	let componentRef = React.useRef()

	useEffect(() => {
		onAssignReportingManagerAccess(buildReportingManagerParam(0))
		mutateActivityTypes(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityType(data)
				},
			}
		)
		mutateActivityStatus(
			{ catId: 9 },
			{
				onSuccess: async data => {
					setActivityStatus(data)
				},
			}
		)
	}, [])

	useEffect(() => {
		const hash = objectHash(widgetData)
		setWidgetDataHash(hash)
		const filters = drilldownFilterStatus?.[hash]?.checkbox || {}
		if (Object.keys(filters).length) {
			toast({
				title: 'Filters restored!',
				status: 'info',
				position: 'top',
				duration: 1000,
				isClosable: true,
			})
		}
		setSelectedCheckbox(filters)
	}, [widgetData])

	useEffect(() => {
		if (!!widgetData) {
			const inlineTagTypeId = JSON.parse(widgetData.filter_tag_type_id)
			loadWidgetHeaders(
				inlineTagTypeId[0].tag_type_id,
				queryFilters.selectedOrganization?.value
			)
			reloadWidgetDrilldown()
		}
	}, [widgetData])

	useEffect(() => {
		if (
			widgetRecords.length < drilldownFilterStatus?.[widgetDataHash]?.pageLoaded
		)
			reloadWidgetDrilldown(false, widgetRecords.length)
	}, [widgetRecords])

	useEffect(() => {
		if (!!widgetExportList && widgetExportList.exceeds) {
			setIsExceedOpen(true)
		}
	}, [widgetExportList])

	useEffect(() => {
		resetFiltersDrilldown()
		if (!!search) {
			onDebounceSearch()
		}
	}, [search])

	useEffect(() => {
		setTimeout(() => {
			reloadWidgetDrilldown(false, 0, true)
		}, 2000)
	}, [dynamicFieldsList])

	useEffect(() => {
		if (!!widgetData) {
			const inlineTagTypeId = JSON.parse(widgetData.filter_tag_type_id)
			const isJson2 =
				!!widgetHeaderList &&
				widgetHeaderList
					.filter(f => f.db_field_flag_is_json === 2)
					.filter(Boolean)
					.reduce((a, s) => s, { db_field_flag_is_json: 0 })
			if (isJson2.db_field_flag_is_json === 2) {
				dynamicFields({
					header_field_name: isJson2.db_field_name,
					tag_type_id: inlineTagTypeId[0].tag_type_id,
				})
			}
		}
	}, [widgetHeaderList, widgetData])

	const callbackFocusRef = useCallback(inputElement => {
		if (inputElement) {
			inputElement.focus()
		}
	}, [])

	const onDebounceSearch = debounce(() => {
		reloadWidgetDrilldown()
	}, 500)

	const resetFiltersDrilldown = () => {
		const filters = drilldownFilterStatus?.[widgetDataHash]
		if (filters)
			dispatchDrilldown({
				type: drilldownConst.SAVE_FILTER_STATUS,
				payload: {},
			})
	}

	const timeColor = datetime => {
		let isDelayed =
			new Date(datetime?.split(' ').join('T')).setHours(0, 0, 0, 0) <
			new Date().setHours(0, 0, 0, 0)
		if (!!isDelayed) {
			return 'red'
		} else {
			return 'black'
		}
	}

	const reloadWidgetDrilldown = (
		isExport = false,
		page = 0,
		isReload = false
	) => {
		if (isExport) {
			loadWidgetExportData({
				widgetData,
				searchStr: search.trim(),
				isExport,
				filter_organization_id: queryFilters?.selectedOrganization?.value,
			})
		} else if (widgetData.widget_type_id === 346) {
			loadSMEDrillDownData(
				{
					organization_id: widgetData.organization_id,
					asset_id: widgetData.asset_id,
					flag: 1,
					summary_id: 7,
				},
				{
					onSuccess: data => {
						let widgetDataRecords = []
						if (!!search || !!isReload) {
							widgetDataRecords = data
							setScrollAtBottom(false)
						} else {
							widgetDataRecords = widgetRecords.concat(data)
						}
						setWidgetRecords(widgetDataRecords)
					},
				}
			)
		} else {
			if (!isWidgetDataLoading && (!!search || !!isReload || !!hasMoreData)) {
				loadWidgetDrillDownData(
					{
						widgetData,
						searchStr: search.trim(),
						page,
						filter_organization_id: queryFilters.selectedOrganization?.value,
					},
					{
						onSuccess: data => {
							// let widgetDataRecords = [];
							if (!!search || !!isReload) {
								// widgetDataRecords = data;
								setWidgetRecords(data)
								setScrollAtBottom(false)
							} else {
								// widgetDataRecords = widgetRecords.concat(data);
								setWidgetRecords(prevState => {
									return [...prevState, ...data]
								})
							}

							// setWidgetRecords(widgetDataRecords);

							if (data.length === 0) setHasMoreData(false)
						},
					}
				)
			}
		}
	}

	const renderWidgetDrilldownHeader = () => {
		let theads =
			!!widgetHeaderList && widgetHeaderList.length
				? widgetHeaderList
						.map((header, i) => {
							return header
						})
						.sort(header => header.sequence_id)
				: null
		let tableHead = theads
		return tableHead
	}

	const participantInfo = (activity_id, field_id, activity_lead_asset_id) => {
		const {
			account_id,
			asset_first_name,
			asset_id,
			asset_image_path,
			asset_last_name,
			asset_phone_number,
			asset_phone_number_code,
			operating_asset_first_name,
			organization_id,
			workforce_id,
		} = authData

		return activity_lead_asset_id === asset_id
			? []
			: [
					{
						access_role_id: 22,
						asset_datetime_last_seen: '1970-01-01 00:00:00',
						activity_id: activity_id,
						asset_type_category_id: 3,
						asset_type_id: 133001,
						field_id: field_id,
						account_id,
						asset_first_name,
						asset_id,
						asset_image_path,
						asset_last_name,
						asset_phone_number,
						asset_phone_number_code,
						log_asset_id: asset_id,
						message_unique_id: getMessageUniqueId(),
						operating_asset_first_name,
						organization_id,
						workforce_id,
					},
			  ]
	}

	const timelineInline = (formFieldsData, form_name, form_id) => {
		let content = 'Form Submitted'
		let body = form_name
		return {
			mail_body: body,
			asset_reference: [
				{
					account_id: '',
					organization: '',
					asset_id: '',
					asset_first_name: '',
					asset_type_category_id: '',
					asset_last_name: '',
					asset_image_path: '',
				},
			],
			activity_reference: [
				{
					activity_title: '',
					activity_id: '',
				},
			],
			form_approval_field_reference: [],
			subject: body,
			attachments: [],
			content: content,
			form_id: form_id,
			form_submitted: formFieldsData,
		}
	}

	const paramAdditional = (value, widget_additional_fields) => {
		let parse = !!widget_additional_fields
			? JSON.parse(widget_additional_fields)
			: {}
		return {
			...parse,
			form_submitted: [value],
		}
	}

	const prepareFormSaveParams = ({ activity, field, value }) => {
		const { activity_id, activity_type_id, activity_lead_asset_id } = activity
		const { form_id, field_id, form_name } = field
		const { activity_type_id: form_workflow_activity_type_id } = activityType
		const { activity_status_id } = activityStatus
		let title = `${formatDDMMYYYYHHMM()}-${form_name}`
		let formFieldsData = [value]
		return {
			activity_id: !!activity_id ? activity_id : 0,
			channel_activity_id: !!activity_id ? activity_id : 0,
			activity_title: title,
			activity_description: title,
			activity_inline_data: JSON.stringify(formFieldsData),
			// data_entity_inline: JSON.stringify(formFieldsData),
			activity_timeline_collection: JSON.stringify(
				timelineInline(formFieldsData, form_name, form_id)
			),
			activity_participant_collection: JSON.stringify(
				participantInfo(activity_id, field_id, activity_lead_asset_id)
			),
			form_id: form_id,
			activity_form_id: form_id,
			workflow_activity_id: !!activity_id ? activity_id : 0,
			activity_type_id: form_workflow_activity_type_id,
			is_refill: 0,
			form_workflow_activity_type_id: activity_type_id,
			generated_group_account_name: null,
			generated_account_code: null,
			activity_title_expression: null,
			gst_number: '',
			pan_number: '',
			activity_datetime_end: getCurrentDateUTC(),
			activity_datetime_start: getCurrentDateUTC(),
			activity_status_id,
			lead_asset_first_name: !!activity
				? activity.activity_lead_operating_asset_first_name
				: '',
			lead_asset_type_id:
				!!activity && activity.activity_lead_asset_type_id
					? activity.activity_lead_asset_type_id
					: null,
			lead_asset_id: !!activity ? activity.activity_lead_asset_id : '',
		}
	}

	const handleChange = (e, field, activity) => {
		let {
			form_id,
			field_id,
			field_reference_id,
			field_name,
			data_type_id,
			data_type_category_id,
		} = field
		let value = {
			form_id,
			field_id,
			field_reference_id,
			field_name,
			field_data_type_id: data_type_id,
			field_data_type_category_id: data_type_category_id,
			data_type_combo_id: e.data_type_combo_id,
			data_type_combo_value: 0,
			field_value: e.value,
			message_unique_id: getMessageUniqueId(),
		}
		setFreezeAct(e => [...e, activity.activity_id])
		mutateSubmitForm(
			{
				...prepareFormSaveParams({ field, activity, value }),
				isOrigin: false,
				form_api_activity_type_category_id:
					activity.activity_type_category_id || 48,
				form_api_activity_type_id: activity.activity_type_id,
			},
			{
				onSuccess: async data => {
					if (!!data.form_transaction_id) {
						value['form_transaction_id'] = data.form_transaction_id
						mutateAdditionalFieldUpdate(
							{
								widget_additional_fields: JSON.stringify(
									paramAdditional(value, activity.widget_additional_fields)
								),
								activity_id: activity.activity_id,
							},
							{
								onSuccess: async data => {
									console.log('data', data)
								},
								onError: async err => {
									console.log('Error', err)
								},
							}
						)
					}
				},
				onError: async err => {
					console.log('Error', err)
				},
			}
		)
	}

	const getCustomColumnData = (headerList, listItem) => {
		return headerList.db_field_flag_is_json === 2
			? !!dynamicFieldsList && !!dynamicFieldsList?.length
				? dynamicFieldsList
						.filter(f => f.data_type_id === 33)
						.map((field, index) => {
							let options = !!field.options ? [...field.options] : []
							const { activity_creator_asset_id, activity_id } = listItem
							const { asset_id } = authData
							const flag_access_setting = field.flag_access_setting
							if (flag_access_setting === 0) {
								return (
									<Select
										key={index}
										id={field.field_name.split(' ').join('-')}
										options={options}
										onChange={e => handleChange(e, field, listItem)}
										placeholder={`Select`}
										borderRadius='md'
										isDisabled={!!freezeAct.includes(activity_id)}
										size='sm'
										maxMenuHeight='250'
									/>
								)
							} else if (flag_access_setting === 1) {
								if (activity_creator_asset_id !== asset_id) {
									return (
										<Select
											key={index}
											id={field.field_name.split(' ').join('-')}
											options={options}
											onChange={e => handleChange(e, field, listItem)}
											placeholder={`Select`}
											borderRadius='md'
											isDisabled={!!freezeAct.includes(activity_id)}
											size='sm'
											maxMenuHeight='250'
										/>
									)
								} else {
									return (
										<Select
											key={index}
											id={field.field_name.split(' ').join('-')}
											options={[]}
											placeholder={`Select`}
											borderRadius='md'
											isDisabled={true}
											size='sm'
											maxMenuHeight='250'
										/>
									)
								}
							} else {
								return null
							}
						})
				: null
			: headerList.db_field_flag_is_json !== 1
			? headerList.data_type_id === 1
				? getCustomFormatttedDate(
						listItem[headerList.db_field_name],
						headerList.conversion_format
				  )
				: headerList.data_type_id === 5
				? getValueInMillions(
						listItem[headerList.db_field_name],
						headerList.conversion_format
				  )
				: listItem[headerList.db_field_name]
			: headerList?.flag_is_reference === 1
			? getInlineReferenceFields(
					headerList,
					listItem[headerList.db_field_json],
					handleReferenceData
			  )
			: headerList.data_type_id === 81
			? getInlineLeaveFields(
					headerList,
					listItem[headerList.db_field_json],
					leaveJson
			  )
			: getInlineFields(headerList, listItem[headerList.db_field_json])
	}

	const handleReferenceData = data => {
		dashboardDispatch({
			type: dashboardConst.SET_TOOLTIP_LOADER,
			payload: true,
		})
		if (!isNaN(data?.activity_id)) {
			mutateActivity(
				{
					activity_id: data?.activity_id,
				},
				{
					onSuccess: async data => {
						let act_data = {}
						if (data?.activity_id) {
							act_data = {
								activity_id: data.activity_id,
								activity_title: data.activity_title,
								tag_type_name: data.tag_type_name,
								activity_status_name: data.activity_status_name,
							}
							dashboardDispatch({
								type: dashboardConst.SET_TOOLTIP_ACTIVITY,
								payload: act_data,
							})
						}
						dashboardDispatch({
							type: dashboardConst.SET_TOOLTIP_LOADER,
							payload: false,
						})
					},
				}
			)
		}
	}

	const leaveJson = e => {
		if (e.length) {
			setLeaveData(e)
			onOpen()
		}
	}

	const getFilteredData = () => {
		const rows = widgetRecords.filter(item => {
			const keyArr = Object.keys(selectedCheckbox)
			if (!!keyArr.length) {
				return keyArr.reduce(function (acc, cur) {
					let label = getCustomColumnData(
						selectedCheckbox[cur][Object.keys(selectedCheckbox[cur])[0]]
							.headerList,
						item
					)
					if (
						acc &&
						selectedCheckbox[cur].hasOwnProperty(!!label ? label : 'Blank')
					)
						return true
					else return false
				}, true)
			}
			return true
		})
		return rows
	}
	const renderWidgetDrilldownData = () => {
		let tableItems = []
		const selectedIndex = drilldownFilterStatus?.[widgetDataHash]?.selectedIndex
		if (!!widgetRecords && widgetRecords.length) {
			tableItems.push(
				getFilteredData().map((listItem, i) => {
					return !!widgetHeaderList && widgetHeaderList.length
						? widgetHeaderList.map((headerList, index) => {
								const value = getCustomColumnData(headerList, listItem)
								const color = timeColor(
									listItem?.activity_datetime_end_deferred
								)
								const fieldData = !!listItem['widget_additional_fields']
									? JSON.parse(listItem['widget_additional_fields'])
									: {}
								const image_path = !!fieldData?.['activity_image_path']
									? fieldData?.['activity_image_path']
									: ''
								return {
									value,
									listItem,
									color: color,
									db_field_name: headerList?.db_field_name,
									header_name: headerList?.header_name,
									activity_image_path: image_path,
									onClickHandler: listItem =>
										headerList.db_field_name === 'activity_title' &&
										!isMobileView
											? checkAssetPrivilege(listItem)
											: null,
								}
						  })
						: null
				})
			)
		}
		if (isWidgetDataLoading) {
			let loader = [1, 2, 3].map(i => i)
			if (scrollAtBottom) {
				tableItems = [...tableItems, ...loader]
			} else {
				tableItems = loader
			}
		}
		return tableItems
	}
	const renderExportData = () => {
		let dataSet = []
		widgetExportList.exports.length &&
			widgetExportList.exports.map(listItem => {
				dataSet.push(parseDataForExcelExport(widgetHeaderList, listItem))
				return null
			})
		return (
			<ExcelFile filename={widgetData.activity_title} hideElement={true}>
				<ExcelSheet data={dataSet} name='Drilldown Data'>
					{!!widgetHeaderList &&
						widgetHeaderList.map(headerItem => (
							<ExcelColumn
								label={headerItem.header_name}
								value={headerItem.db_field_name}
							/>
						))}
				</ExcelSheet>
			</ExcelFile>
		)
	}

	const onAssignReportingManagerAccess = payload => {
		dispatch({
			type: sharedConst.ASSIGN_REPORTING_MANAGER_ACCESS,
			accessType: payload ? payload.accessType : 0,
		})
	}

	const checkDottedLineManagerAccess = item => {
		let payload = {
			organization_id: userData.organization_id,
			asset_id: userData.asset_id,
			activity_id: item.activity_id,
			flag: 1,
			asset_token_auth: userData.asset_token_auth,
			activity_creator_asset_id: item.activity_creator_asset_id,
		}
		mutateCheckDottedLineManagerAccess(payload, {
			onSuccess: data => {
				if (Array.isArray(data) && !data[0].hasOwnProperty('query_status')) {
					onAssignReportingManagerAccess(buildReportingManagerParam(1))
				}
				onSelectActivity(item)
			},
		})
	}

	const checkAssetPrivilege = item => {
		dispatchDrilldown({
			type: drilldownConst.SAVE_FILTER_STATUS,
			payload: {
				[widgetDataHash]: {
					checkbox: selectedCheckbox,
					selectedIndex: item.activity_id,
					pageLoaded: widgetRecords.length,
				},
			},
		})
		setSelectedId(item.activity_id)
		//This flag must be enabled in order for hierchial manager feature to work
		if (userData.organization_flag_enable_manager_proxy !== 1) {
			onSelectActivity(item)
		} else {
			const { operating_asset_first_name, asset_id } = userData
			let creator = {
				operating_asset_first_name,
				asset_id,
			}
			let payload = {
				creator,
				flag: 1,
			}
			mutateLoadReportees(payload, {
				onSuccess: data => {
					if (!data.length) {
						checkDottedLineManagerAccess(item)
					} else {
						//Check for creator
						var isReporteeCreator = data.find(asset => {
							return asset.asset_id === item.activity_creator_asset_id
						})
						if (isReporteeCreator) {
							onAssignReportingManagerAccess(buildReportingManagerParam(1))
							onSelectActivity(item)
						} else {
							//Check for lead
							var isReporteeLead = data.find(asset => {
								return asset.asset_id === item.activity_lead_asset_id
							})
							if (isReporteeLead) {
								onAssignReportingManagerAccess(buildReportingManagerParam(2))
								onSelectActivity(item)
							} else {
								checkDottedLineManagerAccess(item)
							}
						}
					}
				},
			})
		}
	}

	const buildReportingManagerParam = flag => {
		return {
			accessType: flag,
		}
	}

	const onSelectActivity = useCallback(
		activity => {
			dispatch({
				type: sharedConst.ACTVITY_SELECTED,
				payload: activity,
			})
			loadActivity(
				{
					flag: 0,
					is_active: 0,
					is_due: 0,
					is_search: 1,
					is_unread: 0,
					limit_value: 30,
					page_limit: 30,
					page_start: 0,
					queue_id: 0,
					search_string: activity.activity_cuid_1,
					sort_flag: 4,
					start_from: 0,
					status_type_id: 0,
				},
				{
					onSuccess: res => {
						let data = res.list.map(({ asset_participant_access_id }) => ({
							asset_participant_access_id,
						}))
						dispatch({
							type: sharedConst.SET_PARTICIPANT_ACCESS_ID,
							accessID: data[0]?.asset_participant_access_id,
						})
						onWidgetViewChange(WIDGET_WORKSPACE_MODE.CARD_WIDGET_WORKSPACE)
						//onToggle()
					},
				}
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[sharedConst, WIDGET_WORKSPACE_MODE]
	)

	const handleScrollUpdate = values => {
		const { scrollTop, scrollHeight, clientHeight, scrollYPossible } = values
		if (
			scrollHeight - clientHeight === Math.round(scrollTop) &&
			scrollYPossible
		) {
			setScrollAtBottom(true)
			reloadWidgetDrilldown(false, widgetRecords.length)
		}
	}

	const onCellClicked = e => {
		let activity = widgetRecords.find(
			f =>
				f.activity_cuid_1 === e.data.activity_cuid_1 &&
				f.activity_title === e.data.activity_title
		)
		if (!!activity) {
			checkAssetPrivilege(activity)
		}
	}

	let dataSet = []
	!!widgetRecords &&
		widgetRecords.length &&
		widgetRecords
			.filter(f => f.activity_type_category_id === 66)
			.map(listItem => {
				dataSet.push({
					activity_cuid_1: listItem.activity_cuid_1,
					activity_cuid_2: listItem.activity_cuid_2,
					activity_title: listItem.activity_title,
				})
				return null
			})

	return (
		<Box h='100%' w='98%'>
			<Flex>
				<IconButton
					bg='white'
					borderRadius='md'
					boxShadow='md'
					aria-label='Refresh'
					cursor='pointer'
					size='md'
					_focus={{
						bg: 'secondary',
					}}
					_hover={{
						bg: 'secondary',
					}}
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_DASHBOARD,
						// 	action: 'Widget Data',
						// 	label: 'Go Back',
						// })
						dispatchDrilldown({
							type: drilldownConst.SAVE_FILTER_STATUS,
							payload: {
								[widgetDataHash]: {
									checkbox: selectedCheckbox,
									selectedIndex: null,
									pageLoaded: widgetRecords.length,
								},
							},
						})

						onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_VIEW)
						onToggle()
					}}
					icon={<Icon as={BiArrowBack} w={6} h={6} />}
				/>

				<Spacer />
				<Box mr='4'>
					<InputGroup flex={1} borderRadius='md'>
						<Input
							bg='white'
							placeholder={locale['Smart search']}
							borderRadius='md'
							variant='filled'
							boxShadow='md'
							minW='250px'
							isDisabled={queryFilters.selectedSubApplication?.value === 110}
							onChange={e => {
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Drilldown Table',
								// 	buttonName: 'Smart Search',
								// })
								setSearch(e.target.value)
							}}
							_focus={{
								border: 'none',
							}}
						/>
						{/* TODO: provide the option to clear the search text */}
						<InputRightElement
							children={
								<Icon
									as={MdSearch}
									color={localStorage.getItem('color')}
									w={6}
									h={6}
								/>
							}
						/>
					</InputGroup>
				</Box>

				{!isMobileView ? (
					<Box mr='4'>
						<Popover
							isOpen={isExceedOpen}
							onClose={() => setIsExceedOpen(false)}
						>
							<PopoverTrigger>
								<IconButton
									bg='white'
									isDisabled={!widgetRecords || !widgetRecords.length}
									borderRadius='md'
									boxShadow='md'
									aria-label='Refresh'
									cursor='pointer'
									size='md'
									_focus={{
										bg: 'secondary',
									}}
									_active={{
										bg: 'secondary',
									}}
									_hover={{
										bg: 'secondary',
									}}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_DASHBOARD,
										// 	action: 'Widget Data',
										// 	label: 'Export',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Drilldown Table',
										// 	buttonName: 'Export',
										// })
										reloadWidgetDrilldown(true)
									}}
									spinner={<BeatLoader size={8} color='white' />}
									isLoading={isExportDataLoading}
									icon={<Icon as={BiDownload} w={6} h={6} />}
								/>
							</PopoverTrigger>
							<PopoverContent>
								<PopoverArrow />
								<PopoverCloseButton />
								<PopoverHeader>Records exceeded!</PopoverHeader>
								<PopoverBody>
									Sorry, more than 5000 records are found. Please contact
									administrator
								</PopoverBody>
							</PopoverContent>
						</Popover>
					</Box>
				) : null}
				{dataSet.length > 0 && (
					<Box mr='4'>
						<ReactToPrint content={() => componentRef}>
							<PrintContextConsumer>
								{({ handlePrint }) => (
									<IconButton
										bg='white'
										borderRadius='md'
										boxShadow='md'
										aria-label='Print'
										cursor='pointer</Box>'
										size='md'
										_focus={{
											bg: 'secondary',
										}}
										_hover={{
											bg: 'secondary',
										}}
										onClick={e => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_DASHBOARD,
											// 	action: 'Widget Data',
											// 	label: 'Print',
											// })
											// dataLayerTagManager('button_click', {
											// 	viewName: 'Drilldown Table',
											// 	buttonName: 'Print',
											// })
											handlePrint(e)
										}}
										icon={<Icon as={MdPrint} w={6} h={6} />}
									/>
								)}
							</PrintContextConsumer>
						</ReactToPrint>
					</Box>
				)}
				<IconButton
					bg='white'
					borderRadius='md'
					boxShadow='md'
					aria-label='Refresh'
					cursor='pointer'
					size='md'
					_focus={{
						bg: 'secondary',
					}}
					_hover={{
						bg: 'secondary',
					}}
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_DASHBOARD,
						// 	action: 'Widget Data',
						// 	label: 'Refresh',
						// })
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Drilldown Table',
						// 	buttonName: 'Refresh',
						// })
						setHasMoreData(true)
						reloadWidgetDrilldown(false, 0, true)
						setSelectedCheckbox({})
						resetFiltersDrilldown()
					}}
					icon={<Icon as={MdRefresh} w={6} h={6} />}
				/>
				<Tooltip label={'Toggle View'} placement='top'>
					<Box ml='4' display='grid' placeContent='center'>
						<Switch
							defaultChecked={true}
							onChange={e => {
								onWidgetViewChange(WIDGET_WORKSPACE_MODE.WIDGET_DATA)
							}}
						/>
					</Box>
				</Tooltip>
			</Flex>
			<Box
				w='100%'
				h={isMobileView ? 'calc(100vh - 100px)' : 'calc(100vh - 160px)'}
				mt='16px'
				overflow='auto'
				templateColumns='repeat(12, 1fr)'
				gap={5}
			>
				{!isExportDataLoading &&
				!!widgetExportList &&
				widgetExportList.exports.length
					? renderExportData()
					: null}
				{isWidgetHeaderLoading ? (
					<Flex mt='20' justify='center'>
						<PropagateLoader color='#718096' />
					</Flex>
				) : !!widgetHeaderList && widgetHeaderList.length ? (
					<Scrollbar
						onUpdate={widgetRecords.length >= 100 ? handleScrollUpdate : null}
					>
						<DrillDownCard
							theadings={renderWidgetDrilldownHeader()}
							tdata={renderWidgetDrilldownData()}
							thLength={widgetHeaderList.length + 1}
							onCellClicked={onCellClicked}
						/>
					</Scrollbar>
				) : (
					<Flex flexDirection='column' mt='10' align='center' justify='center'>
						<AiFillDatabase size={150} color='#48BB78' />
						<Text color='#48BB78'>No headers configured</Text>
					</Flex>
				)}
			</Box>
		</Box>
	)
}

export default WidgetCardData
